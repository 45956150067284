import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Divider, MenuItem, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../auth/useAuthContext';
import { IconButtonAnimate } from '../../../components/animate';
import { CustomAvatar } from '../../../components/custom-avatar';
import MenuPopover from '../../../components/menu-popover';
import { useSnackbar } from '../../../components/snackbar';
import { useLocales } from '../../../locales';
import { userTimeZone } from '../../../redux/slices/agency';
import { dispatch } from '../../../redux/store';
import { PATH_AUTH } from '../../../routes/paths';
// ----------------------------------------------------------------------
export default function AccountPopover() {
    const navigate = useNavigate();
    const { translate } = useLocales();
    const { user, logout } = useAuthContext();
    useEffect(() => {
        dispatch(userTimeZone());
    }, []);
    const { enqueueSnackbar } = useSnackbar();
    const [openPopover, setOpenPopover] = useState(null);
    const handleOpenPopover = (event) => {
        setOpenPopover(event.currentTarget);
    };
    const handleClosePopover = () => {
        setOpenPopover(null);
    };
    const onIdle = () => {
        logout();
    };
    useIdleTimer({
        onIdle,
        timeout: 60 * 60 * 1000,
    });
    useEffect(() => {
        const handleInvalidToken = (e) => {
            if (e.key === 'accessToken' && e.oldValue && !e.newValue) {
                logout();
            }
        };
        window.addEventListener('storage', handleInvalidToken);
        return function cleanup() {
            window.removeEventListener('storage', handleInvalidToken);
        };
    }, [logout]);
    const handleLogout = async () => {
        try {
            logout();
            navigate(PATH_AUTH.login, { replace: true });
            handleClosePopover();
        }
        catch (error) {
            console.error(error);
            enqueueSnackbar(`${translate('Unable to logout!')}`, { variant: 'error' });
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(IconButtonAnimate, { onClick: handleOpenPopover, sx: {
                    p: 0,
                    ...(openPopover && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                        },
                    }),
                }, children: _jsx(CustomAvatar, { src: user?.photoURL, alt: user?.firstName, name: user?.firstName }) }), _jsxs(MenuPopover, { open: openPopover, onClose: handleClosePopover, sx: { width: 200, p: 0 }, children: [_jsxs(Box, { sx: { my: 1.5, px: 2.5 }, children: [_jsx(Typography, { variant: "subtitle2", noWrap: true, children: user?.firstName }), _jsx(Typography, { variant: "body2", sx: { color: 'text.secondary' }, noWrap: true, children: user?.email })] }), _jsx(Divider, { sx: { borderStyle: 'dashed' } }), _jsx(Divider, { sx: { borderStyle: 'dashed' } }), _jsx(MenuItem, { onClick: handleLogout, sx: { m: 1 }, children: `${translate('Logout')}` })] })] }));
}
